
  import { Vue, Component, Prop } from "vue-property-decorator";
  import { namespace } from "vuex-class";
  import LzLinkIcon from "@/components/LinkIcon.vue";
  import { apiWallet } from "../api";

  import LzButton from "@/components/Button.vue";

  const auth = namespace("auth");

  @Component({
    components: { LzButton, LzLinkIcon }
  })
  export default class MolliePayment extends Vue {
    form = {
      orgApiKey: ""
    };

    @auth.State("id")
    public ongId!: string;

    @Prop() paymentMethod!: PaymentMethod;

    get hideSaveBtn(): boolean {
      return !!(this.mollieOrgApiKey && this.paymentMethod !== "mollie");
    }

    @auth.State("mollieOrgApiKey")
    public readonly mollieOrgApiKey!: string;

    async connectToMollie() {
      try {
        await apiWallet.mollieApi.postConfig(this.ongId, this.form.orgApiKey);
        this.$store.commit("auth/setOngConfig", { payment_method: "mollie" });
        this.$store.commit("auth/setData", {
          mollieOrgApiKey: this.form.orgApiKey
        });

        this.$notify({
          type: "success",
          text: this.$tc("common.notifications.changeSuccess")
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    onSave() {
      this.$emit("paymentMethodChange", "mollie");
    }

    mounted() {
      this.form.orgApiKey = this.mollieOrgApiKey;
    }
  }
