
  import { Component, Vue, Prop } from "vue-property-decorator";

  @Component({})
  export default class LinkIcon extends Vue {
    @Prop({ required: true })
    iconName!: string;

    @Prop({ required: true })
    label!: string;

    @Prop({ required: true })
    link!: string;
  }
