
  import { Component, Prop, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzModal from "@/components/Modal.vue";
  import LzLinkIcon from "@/components/LinkIcon.vue";
  import LzBox from "@/components/Box.vue";
  import { apiOrganizations } from "../modules/organization/api";

  const periods = ["monthly", "semiannual", "yearly"] as const;
  type Period = typeof periods[number];
  type Planes = Record<Period, SubscriptionPlan[]>;
  type SubscriptionPlansList = {
    id: string;
    name: PlatformSubscription["name"];
    priceText: string;
    url: string;
    description: PlanDescription[];
  }[];

  type PlanDescription = {
    text: string;
    status: boolean;
  };

  type OrganizationPlan = {
    id: string;
    PlatformSubscription: PlatformSubscription;
    payment_type: Period;
  };

  @Component({
    components: { LzButton, LzModal, LzLinkIcon, LzBox }
  })
  export default class Subscription extends Vue {
    loaded = false;

    subscriptionOptions = {
      monthly: this.$t("organization.read.subscriptionForm.options.monthly"),
      semiannual: this.$t(
        "organization.read.subscriptionForm.options.semiannual"
      ),
      yearly: this.$t("organization.read.subscriptionForm.options.yearly")
    };

    subscriptionPlans = {} as Planes;

    subscriptionPlansList: SubscriptionPlansList = [];

    organizationPlan = {} as OrganizationPlan;

    @Prop()
    ongId?: string;

    @Prop()
    currencySymbol?: string;

    redirectToPlan(url: string) {
      if (url) {
        window.location.href = url;
      }
    }

    loadSubscriptionPlans(period: Period): void {
      const currency_text = this.$t("organization.read.priceText", {
        currency: this.currencySymbol ?? "€"
      });

      this.subscriptionPlansList = this.subscriptionPlans[period]
        ?.map(({ id, name, price, description, payment_url }) => {
          const formattedDescription = description
            .split(".")
            .reduce((acc, current) => {
              if (!current) return acc;

              const [text, status] = current.replace("\n\n", "").split(", ");

              return acc.concat({
                text: this.$t(
                  `organization.read.planDescription.${text}`
                ) as string,
                status: status === "true"
              });
            }, [] as PlanDescription[]);

          return {
            id,
            name: this.$t(
              `organization.read.planNames.${name}`
            ) as PlatformSubscription["name"],
            priceText: `${price} ${currency_text}`,
            url: payment_url,
            description: formattedDescription
          };
        })
        .sort(a => {
          if (a.name.includes("Basic") || a.name.includes("Básico")) return -1;
          else return 0;
        }); // sort to make basic planes on the left side
    }

    isSelectedPlan(plan: string) {
      return plan === this.organizationPlan?.PlatformSubscription?.id;
    }

    async getSubscriptionPlans() {
      const { data: plans } = await apiOrganizations.getSubscriptionPlans();

      this.subscriptionPlans = periods.reduce((acc, period) => {
        acc[period] = plans.filter(
          plan => plan.period === period // @todo - Quitar cuando el backend lo quite de la db
        );
        return acc;
      }, {} as Planes);
    }

    async getOrganizationPlan(): Promise<Period> {
      if (!this.ongId) return "monthly";

      const {
        data: { id, payment_type, PlatformSubscription }
      } = await apiOrganizations.getOrganizationPlan(this.ongId);

      this.organizationPlan.id = id;
      this.organizationPlan.PlatformSubscription = PlatformSubscription;
      this.organizationPlan.payment_type = payment_type ?? "monthly";

      return this.organizationPlan.payment_type;
    }

    async mounted() {
      try {
        await this.getSubscriptionPlans();
        const planPeriod = await this.getOrganizationPlan();

        this.loadSubscriptionPlans(planPeriod);
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      } finally {
        this.loaded = true;
      }
    }
  }
