
  import { Component, Prop, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzModal from "@/components/Modal.vue";
  import LzLinkIcon from "@/components/LinkIcon.vue";
  import { namespace } from "vuex-class";
  const auth = namespace("auth");

  @Component({
    components: { LzButton, LzModal, LzLinkIcon }
  })
  export default class StripePayment extends Vue {
    connectToStripeLink = `https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=${process.env.VUE_APP_STRIPE_CLIENT_ID}&scope=read_write`;

    @auth.State("id")
    public ongId!: string;

    @Prop() paymentMethod!: PaymentMethod;

    @auth.State("stripeId")
    public readonly stripeId!: string;

    @auth.State("ongConfiguration")
    public ongConfiguration!: any;

    connectToStripe() {
      window.open(this.connectToStripeLink, "_blank");
    }

    get hideSaveBtn(): boolean {
      return !!this.stripeId && this.paymentMethod !== "stripe";
    }

    onSave() {
      this.$emit("paymentMethodChange", "stripe");
    }
  }
