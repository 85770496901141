
  import { Component, Prop, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzModal from "@/components/Modal.vue";
  import LzLinkIcon from "@/components/LinkIcon.vue";
  import { namespace } from "vuex-class";
  import { apiWallet } from "../api/";
  import { apiOngs } from "@/modules/web/api";

  const auth = namespace("auth");

  @Component({
    components: { LzButton, LzModal, LzLinkIcon }
  })
  export default class PaymentPaypal extends Vue {
    form = {
      id: "",
      secret: ""
    };

    get hideSaveBtn(): boolean {
      return (
        !!this.paypalClientId &&
        !!this.paypalClientSecret &&
        this.paymentMethod !== "paypal"
      );
    }

    @Prop() paymentMethod!: PaymentMethod;

    @auth.State("id")
    public ongId!: string;

    @auth.State("paypalClientId")
    public paypalClientId?: string;

    @auth.State("ongConfiguration")
    public ongConfiguration!: any;

    @auth.State("paypalClientSecret")
    public paypalClientSecret?: string;

    @auth.Mutation
    public setPaypalConfig!: (payload: Record<"id" | "secret", string>) => void;

    async submitForm() {
      try {
        await apiWallet.paypalApi.postConfig(
          this.ongId,
          this.form.id,
          this.form.secret
        );
        this.setPaypalConfig({ id: this.form.id, secret: this.form.secret });

        await apiOngs.postPlatformConfig(this.ongId, {
          ...this.ongConfiguration,
          payment_method: "paypal"
        });

        this.$store.commit("auth/setOngConfig", { payment_method: "paypal" });

        this.$notify({
          type: "success",
          text: this.$tc("common.notifications.changeSuccess")
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      }
    }

    onSave() {
      this.$emit("paymentMethodChange", "paypal");
    }
    async mounted() {
      this.form.id = this.paypalClientId || "";
      this.form.secret = this.paypalClientSecret || "";
    }
  }
