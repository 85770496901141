
  import { Vue, Component, Prop } from "vue-property-decorator";
  import StripePayment from "../components/StripePayment.vue";
  import PaymentPaypal from "../components/PaymentPaypal.vue";
  import MolliePayment from "../components/MolliePayment.vue";
  import { namespace } from "vuex-class";

  const auth = namespace("auth");

  @Component({
    components: {
      StripePayment,
      PaymentPaypal,
      MolliePayment
    }
  })
  export default class PaymentCardView extends Vue {
    public paymentComponents = {
      stripe: StripePayment,
      paypal: PaymentPaypal,
      mollie: MolliePayment
    };

    @auth.State("id")
    public ongId!: string;

    @Prop() title!: string;
    @Prop() features!: string[];
    @Prop() odds!: string[];
    @Prop() paymentMethod!: PaymentMethod;

    get capitalizedTitle() {
      if (this.title) {
        return this.title.charAt(0).toUpperCase() + this.title.slice(1);
      }
      return "";
    }

    async changePaymentMethod(paymentMethod: PaymentMethod) {
      this.$emit("save", paymentMethod);
    }

    mounted() {
      this.$forceUpdate();
    }
  }
